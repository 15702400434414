export const BASE_URL = process.env.REACT_APP_API_BASE_URL;
//export const BASE_URL = "https://www.squareinsurance.in/testing/new_motor/";
export const BASE_NODE_URL = process.env.REACT_APP_NODE_BASE_URL;
//export const BASE_NODE_URL = "https://node.squareinsurance.in:3000/";
export const COMMON_API_BASE_URL = process.env.REACT_APP_COMMON_API_BASE_URL;
export const BASE_FUTURE_KYC_RETRUN_URL = `${process.env.REACT_APP_API_BASE_URL}future-ckyc-success-redirection/`;
export const BASE_ICICI_MOTOR_KYC_RETRUN_URL = `${process.env.REACT_APP_API_BASE_URL}future-ckyc-success-redirection/`;
export const DATE_FORMAT_DDMMYYYY = "dd/MM/yyyy";
export const PAN_CARD_LIMIT = 49000;
export const S3_BASE_URL = "https://let-secure-production-s3-bucket.s3.ap-south-1.amazonaws.com/web/";
export const CRYPTO_KEY = "0123456789abcdef0123456789abcdef";
export const CRYPTO_IV = "abcdef9876543210abcdef9876543210";

export const COMMON_PATH = {
    "registration" : "prequotes/get-vehicle-detail",
    "manufacturer" : "prequotes/get-manufacturer-detail",
    "rto" : "prequotes/get-rtos",
    "model" : "prequotes/get-model",
    "fuel" : "prequotes/get-fuel-type",
    "variants" : "prequotes/get-variants",
    "insurer" : "prequotes/get-insurer-company",
    "year" : "prequotes/get-register-year",
    "map_to" : "show-mapping-users",
    "map_user" : "update-mapping-users",
};

export const ADDON_NAMES_KEY = ["price_with_gst","imt23","imt34","imt47","emergency_cover","zero_dept","consumables","tyre_cover","ncb_protection","engine_protector","return_invoice","loss_of_key","road_side_assistance","passenger_assist_cover","loss_personal_belonging","hospital_cash_cover","engine_protector_expiring_policy","rti_expiring_policy","tyre_cover_expiring_policy","zero_dept_expiring","cng_lpg_expiring_policy","zero_dept_expiring_policy","emergency_medical_expenses","helmet_protection","inconvenience_allowance","battery_cover"];